.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  display: flex;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  gap: 1rem;
  flex-direction: column;
  flex: 1 1;
  background: white;
  overflow-y: auto;
  background: rgb(252, 253, 255);
  padding: 1rem;
  padding-top: 2.5rem;
  box-shadow: 0 4px 20px rgba(147, 152, 199, 1);
  box-sizing: border-box;
}

.mainTitle {
  font-weight: 500;
  color: #1E293B;
  margin: 0;
  display: flex;
  align-items: center;
}

.subtitle {
  font-size: 0.8125rem;
  color: #64748B;
}

.header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 52px;
  width: calc(100% - 3rem);
}

.headerTitle {
  font-size: 13px;
  font-weight: 500;
  color: #64748B;
}

.required {
  color: #EF4444;
  margin-left: 4px;
}

.steps {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.stepRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.inputGroup {
  display: flex;
  align-items: center;
  gap: 12px;
}

.inputWrapper, .inputGroup {
  flex: 1;
}

.input {
  width: 100%;
  height: 36px;
  padding: 0 12px;
  border: 1px solid rgba(79, 99, 190, 0.2);
  border-radius: 6px;
  font-size: 14px;
  color: #1E293B;
  transition: all 0.2s ease;
  box-sizing: border-box;
}

.input:hover {
  border-color: #4F63BE;
}

.input:focus {
  outline: none;
  border-color: #4F63BE;
  box-shadow: 0 4px 20px rgba(147, 152, 199, 0.25);
}

.inputError {
  border-color: #EF4444;
}

.inputError:hover {
  border-color: #EF4444;
}

.inputError:focus {
  border-color: #EF4444;
  box-shadow: 0 4px 20px rgba(239, 68, 68, 0.25);
}

.arrowWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow {
  color: #64748B;
}

.deleteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.375rem;
  height: 2.375rem;
  border: none;
  background: rgba(167, 172, 233, 0.1);
  color: #4F63BE;
  cursor: pointer;
  transition: 0.2s ease;
  flex-shrink: 0;
}

.deleteButton:hover {
  background: #FEE2E2;
  color: #EF4444;
  box-shadow: 0 4px 20px rgba(147, 152, 199, 1);
}

.deleteButton:active {
  transform: translateY(1px);
}

.addButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 36px;
  height: 36px;
  margin: 0 0 0 auto;
  background: #4F63BE;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.addButton > svg {
  stroke-width: 4;
  height: 1rem;
  width: 1rem;
  stroke-linecap: square;
}

.addButton.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: #F8FAFC;
  color: #94A3B8;
  pointer-events: none;
}

.addButton:hover:not(.disabled) {
  background: #3b4d9b;
  box-shadow: 0 4px 20px rgba(147, 152, 199, 1);
}

.addButton:active:not(.disabled) {
  transform: translateY(1px);
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .content {
    padding: 0.75rem;
  }

  .header {
    gap: 24px;
    padding: 0;
    margin-bottom: 1rem;
  }

  .stepRow {
    grid-template-columns: 1fr;
    gap: 12px;
  }

  .arrowWrapper {
    display: none;
  }

  .inputWrapper,
  .inputGroup {
    padding: 0;
  }

  .input {
    height: 36px;
  }

  .addButton {
    width: 100%;
    margin: 0.75rem 0;
  }
}