
.appBar {
    z-index: 1201; /* Adjust z-index to ensure it stays above the drawer */
    background-color:#F5F9FF;
    height: 80px;
    box-shadow: none;
    border-bottom: 2px solid #ffffff;
  }
  
  .toolbar {
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .drawerPaper {
    width: 10px; /* Adjusted drawer width */
    box-sizing: border-box;
    background-color: #F5F9FF;
    border-right: none;
  }
  
  .profileSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
  }
  
  .avatar {
    margin-right: 8px;
  }
  
  .mainContent {
    flex-grow: 1;
    background-color: #F5F9FF;
    padding: 24px;
    margin-left: 10px; /* Match with the drawer width */
  }
  
  .card {
    border-radius: 24px!important;
    background-color: transparent!important;
    border: '1px solid #d3d3d3'!important;
    min-height: 129px;
    padding: 10px;
  }
  
  .cardContent {
    padding: 8px;
  }
  
  .statusCardTitle {
    color: #5E5E5E!important;
    margin-bottom: 30px!important;
    font-size: 16px!important;
    font-weight: 600!important;
    line-height: 19.36px!important;
    letter-spacing: 0.01em!important;
    text-align: left!important;

  }
  .statusCardCount {
    font-size: 2rem!important ;
    color: #232323!important;
    margin-top: 20px!important;
    margin-bottom: 13px!important;
    font-size: 28px!important;
    font-weight: 700!important;
    line-height: 33.89px!important;
    letter-spacing: 0.01em!important;
    text-align: left!important;

  }
  .statusNumber {
    font-size: 1.5rem;
  }
  /* Additional styles for the new box */
.infoCard {
    border-radius: 20px!important;
    background-color: #ffffff; /* White background */
    box-shadow: none;
    height:70vh;
    padding: 10px;
    margin-top: 2px;
    min-height: 80vh; /* Adjust height as needed */
  }
  .imageCard {
    border-radius: 8px;
    background-color: #F5F9FF!important; /* White background */
    box-shadow: none;
    padding: 16px;
    margin-top: 2px;
    min-height: 300px; /* Adjust height as needed */
    text-align: center; /* Center content */
  }
  .imageContainer {
    width: 100%;
    height: 165px;
    /* background: radial-gradient(circle, #FFFFFF, #6B6B6B);  */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px; 
    overflow: hidden;
  }
  .imageContainer2 {
    width: 100%;
    height: 165px;
    /* background: radial-gradient(circle, #FFFFFF, #7398C7);  */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px; 
    overflow: hidden;
  }
  .cardImage {
    width: 188px;
    height: 141px;
    top: 199px;
    left: 508px;
    gap: 0px;
    opacity: 0px;
  }
  .cardImage {
    width: 100%;
    max-height: 150px; /* Adjust image height */
    object-fit: cover; /* Ensure the image covers the box without distortion */
    border-radius: 4px; /* Rounded corners */
    margin-bottom: 16px; /* Space between image and text */
  }
  
  .cardText {
    text-align: left;
    font-size: 1rem;
    color: #333333; /* Text color */
    margin-bottom: 16px; /* Space between text and button */
  }
  
  .viewButton {
    background-color: #1976d2; /* Button background color */
    color: #ffffff; /* Button text color */
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.875rem;
  }

  .imageCard .imageBox {
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
    border-radius: 50%;
    padding: 20px;
  }
  
  .imageCard .cardImage {
    width: 100%;
    border-radius: 50%;
  }
  
  .imageCard .cardText {
    margin-top: 16px;
  }
  
  .imageCard .viewButton {
    margin-top: 16px;
    text-align: center;
  }