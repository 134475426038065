.runDetailsContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  color: #000000;
}

.header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}

.tabs {
  display: flex;
  gap: 20px;
  padding-left: 24px;
}

.tabButton {
  background: none;
  border: none;
  font-size: 16px;
  padding: 0.4rem;
  cursor: pointer;
  color: #000000;
  position: relative;
  font-weight: 400;
  border-radius: 0;
}

.activeTab {
  color: #4f63be;
  border-bottom: 3px solid #4f63be;
  font-weight: 700;
}

.runDetailsContent {
  display: flex;
  flex-direction: column;
  border: 1.8px solid #eceef6;
  border-radius: 25px;
  padding: 10px 0;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  min-height: 5rem;
}

.runDetailsContent > p {
  text-align: center;
}

.detailsTable {
  width: 100%;
  border-collapse: collapse;
  padding-bottom: 24px;
}

.th,
.td {
  text-align: left;
  padding: 0 24px;
  height: 50px;
  font-size: 14px;
  color: #333333;
}

.th {
  color: #949494;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  letter-spacing: 0.01em;
}

.td {
  color: #333333;
  font-weight: 600;
  padding: 10px 24px;
}

.tr:last-child > .td {
  padding-bottom: 10px;
}

.tr:hover {
  background-color: #f2f2f2;
}

.tr:hover .th,
.tr:hover .td {
  color: #4f63be;
}

.tr > .th {
  border-bottom: 1px solid #efefef;
}

.noHover:hover {
  background-color: transparent;
}

.noHover:hover .th,
.noHover:hover .td {
  color: #949494;
}

.automationResultContainer {
  display: flex;
  flex-direction: column;
}

.automationResultHeader {
  color: #4f63be;
  font-weight: bold;
  padding: 0 24px;
  height: 50px;
  font-size: 14px;
  display: flex;
  align-items: center;
  background: #f2f2f2;
}

.automationResultBody {
  font-size: 14px;
  color: #333333;
}

.logEntry {
  height: 50px;
  padding: 0 24px;
  display: block;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
