/* Root container */
.workflowBuilder {
  display: flex;
  flex-direction: column;
  background-color: #f5f9ff;
  width: 100%;
  min-height: 100vh;
  position: relative;
  isolation: isolate;
}

/* Header and main content wrapper */
.headerAndContent {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  background-color: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  margin: 0;
  position: relative;
  overflow: hidden;
  margin-top: 70px;
}

/* Main content area */
.mainContent {
  display: flex;
  flex: 1 1 auto;
  min-height: 0; /* Enables proper flex scrolling */
  position: relative;
}

/* Sidebar filter */
.filterSidebar {
  width: clamp(250px, 16.25rem, 20vw);
  background-color: #271919;
  border-right: 1px solid #eceef6;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: width 0.3s ease;
}

/* Main content container */
.contentContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  position: relative;
}

/* Scrollable content area */
.contentContainer-scroll {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.contentContainer-scroll::-webkit-scrollbar {
  width: 6px;
}

.contentContainer-scroll::-webkit-scrollbar-track {
  background: transparent;
}

.contentContainer-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

/* Popup styling */
.popupContent {
  width: 100%;
  max-width: 600px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem;
}

.popupContent > p {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #666666;
  margin: 0;
}

/* Button container */
.popupButtons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  margin-top: 1rem;
}

/* Button styles */
.saveButton,
.continueButton {
  color: white;
  border: none;
  border-radius: 24px;
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
}

.saveButton {
  background-color: #7398c7;
}

.saveButton:hover {
  background-color: #5f84b3;
}

.continueButton {
  background-color: #4f63be;
}

.continueButton:hover {
  background-color: #3f50a0;
}

/* Responsive design */
@media screen and (max-width: 768px) {
  .filterSidebar {
    width: 100%;
    position: absolute;
    z-index: 10;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
  }

  .filterSidebar.open {
    transform: translateX(0);
  }

  .popupContent {
    padding: 1rem;
  }

  .popupButtons {
    flex-direction: column;
    width: 100%;
  }

  .saveButton,
  .continueButton {
    width: 100%;
  }
}

/* Print styles */
@media print {
  .workflowBuilder {
    background: white;
    margin: 0;
    padding: 0;
  }

  .filterSidebar {
    display: none;
  }

  .contentContainer {
    box-shadow: none;
    margin: 0;
  }
}