.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  gap: 1rem;
  padding: 2rem;
  justify-items: start;
  justify-content: start;
}

.tileCard {
  border: 0.0625rem solid #eceef6;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  background-color: #fff;
  width: 100%;
  max-width: 16rem;
  text-align: center;
  padding: 1rem;
  margin: 0 auto;
}

.tileCard:hover {
  transform: translateY(-0.3125rem);
  box-shadow: 0 0.375rem 0.9375rem rgba(0, 0, 0, 0.1);
}

.tileTitle {
  font-weight: 500;
  font-size: 1rem;
  color: #333333;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.tileCategory {
  font-weight: 400;
  font-size: 0.875rem;
  color: #949494;
  margin-bottom: 1rem;
}

.tileIcon {
  font-size: 2rem;
  color: #4f63be;
}

/* Infinite scroll loader styles */
.loaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem 2rem;
  gap: 0.5rem;
  width: 100%;
  margin-top: 1rem;
}

.arrow {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 12px solid #4f63be;
  animation: bounce 1s infinite;
}

.arrow.loading {
  opacity: 0.5;
}

.loaderText {
  font-size: 0.875rem;
  color: #666;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
}