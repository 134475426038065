.wrapper {
  position: relative;
  width: 100%;
}

.row {
  display: flex;
  align-items: flex-start;
  padding: .5rem;
  gap: 1rem;
  margin-bottom: 0.75rem;
  transition: 0.2s ease;
  width: 100%;
  box-sizing: border-box;
}

.leftContent {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  flex: 1;
  min-width: 0;
}

.indexCell {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.75rem;
  height: 1.75rem;
  background: #F8FAFC;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: #64748B;
  margin-top: 1.5rem;
  flex-shrink: 0;
}

.functionCell {
  width: 20rem;
  flex-shrink: 0;
}

.parameterCells {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12.5rem, 1fr));
  gap: 1rem;
  flex: 1;
  min-width: 0;
}

.label {
  font-size: 0.8125rem;
  color: #64748B;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-bottom: 0.375rem;
}

.required {
  color: #EF4444;
}

.dropdownWrapper {
  position: relative;
  width: 100%;
}

.errorField {
  border-color: #EF4444;
}

.errorField:hover,
.errorField:focus {
  border-color: #EF4444;
  box-shadow: 0 0 0 0.1875rem rgba(239, 68, 68, 0.08);
}

.errorMessage {
  display: flex;
  align-items: flex-start;
  gap: 0.25rem;
  font-size: 0.75rem;
  line-height: 1.3;
  color: #EF4444;
  background: white;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  margin-top: 0.25rem;
}

.errorIcon {
  flex-shrink: 0;
  stroke-width: 0.125rem;
  margin-top: 0.0625rem;
}

.deleteCell {
  margin-top: 1.5rem;
}

.deleteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.375rem;
  height: 2.375rem;
  border: none;
  background: rgba(167, 172, 233, 0.1);
  color: #4F63BE;
  cursor: pointer;
  transition: 0.2s ease;
  flex-shrink: 0;
}

.deleteButton:hover {
  background: #FEE2E2;
  color: #EF4444;
  box-shadow: 0 4px 20px rgba(147, 152, 199, 1);
}

.chevron {
  color: #64748B;
}

@media (max-width: 75rem) {
  .functionCell {
    width: 9.375rem;
  }

  .parameterCells {
    grid-template-columns: repeat(auto-fit, minmax(11.25rem, 1fr));
  }
}

@media (max-width: 48rem) {
  .row {
    flex-direction: column;
    padding: 0.75rem;
    gap: 0.75rem;
  }

  .leftContent {
    flex-direction: column;
    width: 100%;
    gap: 0.75rem;
  }

  .functionCell {
    width: 100%;
  }

  .parameterCells {
    grid-template-columns: 1fr;
    width: 100%;
  }

  .indexCell,
  .deleteCell {
    margin-top: 0;
  }
}
