.actionBar {
  display: flex;
  gap: 0.5rem;
}

.button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.resetButton {
  background: rgba(167, 172, 233, 0.1);
  color: #4F63BE;
}

.resetButton:not(:disabled):hover {
  background: #4F63BE;
  border-color: #4F63BE;
  color: white;
  box-shadow: 0 4px 20px rgba(147, 152, 199, 1);
}

.resetButton:active:not(:disabled) {
  transform: translateY(1px);
}

.saveButton {
  background: #4F63BE;
  color: white;
  border: none;
}

.saveButton:not(:disabled):hover {
  background: #3b4d9b;
  box-shadow: 0 4px 20px rgba(147, 152, 199, 1);
}

.saveButton:active:not(:disabled) {
  transform: translateY(1px);
}