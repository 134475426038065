.container {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
  
  .content {
    flex: 1 1;
    background: rgb(252, 253, 255);
    padding: 1rem 2rem;
    padding-top: 2.5rem;
    box-shadow: 0 4px 20px rgba(147, 152, 199, 0.1);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  
  .header {
    margin-bottom: 1.5rem;
  }
  
  .headerTitle {
    font-weight: 500;
    color: #1E293B;
    margin: 0;
    display: flex;
    align-items: center;
  }
  
  .headerSubtitle {
    font-size: 0.8125rem;
    color: #64748B;
  }
  
  .required {
    color: #EF4444;
    margin-left: 0.25rem;
  }
  
  .itemsList {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    margin-bottom: 1rem;
  }
  
  .addButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.25rem;
    height: 2.25rem;
    margin: 0 0 0 auto;
    background: #4F63BE;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .addButton > svg {
    stroke-width: 2;
    height: 1rem;
    width: 1rem;
  }
  
  .addButton.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background: #F8FAFC;
    color: #94A3B8;
    pointer-events: none;
  }
  
  .addButton:hover:not(.disabled) {
    background: #3b4d9b;
  }
  
  .addButton:active:not(.disabled) {
    transform: translateY(1px);
  }
  
  @media (max-width: 48rem) {
    .content {
      padding: 0.75rem;
    }
  
    .addButton {
      width: 100%;
      margin: 1rem 0 0 0;
    }
  }