.notificationItem {
  display: flex;
  gap: 1rem;
  padding: .8rem;
  background: #F5F9FF;
  cursor: pointer;
  transition: all 0.2s ease;
 }
 
 .notificationItem:hover {
  background: #F8FAFC;
  border-color: #E2E8F0;
 }
 
 .selected {
  border-left: 3px solid #3B82F6;
  background: #EBF5FF;
  border-radius: 0 8px 8px 0;
  padding-left: calc(1rem - 3px);
 }
 
 .selected:hover {
  background: #E0F2FE;
  border-left-color: #2563EB;
 }
 
 .checkboxWrapper {
  position: relative;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  z-index: 1;
 }
 
 .checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 18px;
  height: 18px;
  z-index: 2;
 }
 
 .checkmark {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  background-color: white;
  border: 1.5px solid #CBD5E1;
  border-radius: 4px;
  transition: all 0.2s ease;
 }
 
 .checkbox:checked ~ .checkmark {
  background-color: #3B82F6;
  border-color: #3B82F6;
  color: white;
 }
 
 .checkbox:checked ~ .checkmark svg {
  opacity: 1;
  transform: scale(1);
 }
 
 .checkmark svg {
  opacity: 0;
  transform: scale(0.8);
  transition: all 0.2s ease;
 }
 
 .notificationContent {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
 }
 
 .header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
 }
 
 .title {
  margin: 0;
  font-size: 0.938rem;
  font-weight: 700;
  color: #94A3B8;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
 }
 
 .unread .title {
  font-weight: 700;
  color: #0F172A;
 }
 
 .timeStamp {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding-top: .2rem;
  color: #94A3B8;
  font-size: 0.75rem;
  white-space: nowrap;
 }
 
 .description {
  margin: 0;
  font-size: 0.875rem;
  color: #94A3B8;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
 }
 
 .unread .description {
  color: #334155;
 }
 
 .actions {
  display: flex;
    gap: 0.75rem;
    margin-top: 0.5rem;
    justify-content: flex-end;
    align-items: center
 }
 
 .actionButton {
  display: flex;
  align-items: center;
  gap: 0.375rem;
  padding: 0.375rem 0.75rem;
  border: none;
  border-radius: 6px;
  font-size: 0.813rem;
  cursor: pointer;
  transition: all 0.2s ease;
  background: transparent;
 }
 
 .readButton {
  color: #4f63be;
 }
 
 .readButton:hover {
  background: #EFF6FF;
 }
 
 .deleteButton {
  color: #64748B;
 }
 
 .deleteButton:hover {
  background: #F1F5F9;
  color: #475569;
 }
 