:root {
    --dot-size: .2rem;
    --max-block-size: calc(var(--dot-size) * 15);
    --dot-color: black;
    --dot-color-transition: #7398C7;
  }
  
  .loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    block-size: var(--max-block-size);
  }
  
  .dot {
    inline-size: var(--dot-size);
    block-size: var(--dot-size);
    border-radius: 10%;
    background-color: var(--dot-color);
    animation: wave-grow 2s infinite ease-in-out;
    animation-delay: var(--delay);
  }
  
  @keyframes wave-grow {
    0%, 100% {
      block-size: var(--dot-size);
      background-color: var(--dot-color);
    }
    25% {
      block-size: var(--max-block-size);
      background-color: var(--dot-color-transition);
    }
    50% {
      block-size: var(--dot-size);
      background-color: var(--dot-color);
    }
  }
  