.buttonGroup {
  display: flex;
  gap: 10px;
}


.statusDotsContainer {
  display: flex;
 
  align-items: center;
}


.statusDot {
  display: flex;
  height: 10px;
  border-radius: 15px;
}  


.myWorkflowsTable {
  margin-top: 20px;
  /* Add your table styles here */
}
.statusDotsContainer {
  display: flex;
  align-items: center;
}


.statusDot {
  display: flex;
  align-items: center;
  margin-right: 15px; /* Adjust spacing between dots */
}


.successDot {
  width: 12px;
  height: 12px;
  background-color: green;
  border-radius: 50%;
}


.inProgressDot {
  width: 12px;
  height: 12px;
  background-color: blue;
  border-radius: 50%;
}


.warningDot {
  width: 12px;
  height: 12px;
  background-color: yellow;
  border-radius: 50%;
}


.errorDot {
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
}


.dotText {
  margin-left: 5px; /* Space between dot and text */
  font-size: 14px; /* Adjust font size as needed */
}

