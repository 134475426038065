
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 1000;
  margin-top: 72px;
}

.popupContainer {
  --popup-min-width: 320px;
  --popup-min-height: 200px;
  --popup-max-width: min(90vw, 800px);
  --popup-max-height: min(85vh, 600px);
  
  position: fixed;
  width: var(--popup-max-width);
  height: var(--popup-max-height);
  min-width: var(--popup-min-width);
  min-height: var(--popup-min-height);
  background: #fff;
  border-radius: 12px;
  box-shadow: 
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06),
    0 20px 25px -5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 1001;
  will-change: transform;
  touch-action: none;
  top: 10%;
  left: 25%
}

.popupContainer[data-expanded="true"] {
  width: 100vw !important;
  height: calc(100vh - 64px) !important;
  border-radius: 0;
  transform: none !important;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.headerWrapper {
  display: flex;
  align-items: center;
  padding: 0.75rem 2rem;
  background: #fff;
  border-bottom: 1px solid #f1f5f9;
  user-select: none;
  gap: 0.75rem;
  cursor: move;
  height: 3.5rem;
  box-sizing: border-box;
}

.headerWrapper:active {
  cursor: grabbing;
}

.dragHandle {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  min-width: 0;
}

.gripIcon {
  color: #94a3b8;
  transition: color 0.2s;
}

.headerWrapper:hover .gripIcon {
  color: #64748b;
}

.popupHeader {
  margin: 0;
  font-size: 0.938rem;
  font-weight: 600;
  color: #1e293b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contentContainer {
  flex: 1;
  overflow: auto;
  background: #fff;
  position: relative;
}

.iconWrapper {
  display: flex;
  gap: 0.25rem;
  cursor: default;
}

.iconButton {
  width: 28px;
  height: 28px;
  padding: 0;
  border: none;
  background: transparent;
  border-radius: 6px;
  color: #94a3b8;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.iconButton:hover {
  background: #f1f5f9;
  color: #1e293b;
}

.closeButton {
  background: rgba(239, 68, 68, 0.1);
  color: #ef4444;
}

.closeButton:hover {
  background: #ef4444;
  color: white;
  transform: scale(1.05);
}

.actionIcon {
  transition: transform 0.2s;
}

.iconButton:hover .actionIcon {
  transform: scale(1.1);
}

.dragging {
  opacity: 0.95;
  box-shadow: 
    0 8px 12px -1px rgba(0, 0, 0, 0.15),
    0 4px 8px -1px rgba(0, 0, 0, 0.1),
    0 30px 35px -5px rgba(0, 0, 0, 0.15) !important;
}

.dragging * {
  cursor: grabbing !important;
}

@media (max-width: 1440px) {
  .popupContainer[data-expanded="true"] {
    height: calc(100vh - 4rem) !important;
  }
}

@media (prefers-reduced-motion: reduce) {
  .popupContainer,
  .actionIcon {
    transition: none !important;
  }
}

