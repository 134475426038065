.tableHeader {
  display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: .5rem;
    background: rgba(167, 172, 233, 0.05);
    border-radius: 0.5rem;
    border: 1px solid rgba(167, 172, 233, 0.1);
}

.instructionContent {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #4F63BE;
}

.infoIcon {
  flex-shrink: 0;
  color: #4F63BE;
}

.instructions {
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 500;
}