@import 'react-datepicker/dist/react-datepicker.css';
@import './assetts/styles/common/datepicker.css'; 

.App {
  text-align: center;
  font-family: 'Inter', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
/* Universal scrollbar styling */
::-webkit-scrollbar {
  width: 16px !important;
  height: 16px !important;
}

::-webkit-scrollbar-track {
  background: #ECEEF6 !important;
  border-radius: 8px !important;
  margin: 2px !important;
}

::-webkit-scrollbar-thumb {
  background: #7398C7 !important;
  border-radius: 60px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #6384b3 !important;
}

* {
  scrollbar-width: thin !important;
  scrollbar-color: #e2edfd #f2f5fa !important;
}

::-webkit-scrollbar-corner {
  background: #ECEEF6 !important;
}

* {
  -webkit-overflow-scrolling: touch !important;
}

* {
  -ms-overflow-style: none !important;
}

button {
  border-radius: 1.5rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}