.container {
  --primary: #4F63BE;
  --error: #EF4444;
  --border: #E2E8F0;
  --text: #1E293B;
  --text-secondary: #64748B;
  
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}

.content {
  flex: 1 1;
  background: white;
  overflow-y: auto;
  background: rgb(252, 253, 255);
  padding: 1rem;
  padding-top: 2.5rem;
  box-shadow: 0 4px 20px rgba(147, 152, 199, 0.3);
}

.header {
  margin-bottom: 1.5rem;
}

.title {
  font-weight: 500;
  color: #1E293B;
  margin: 0;
  display: flex;
  align-items: center;
}

.subtitle {
  font-size: 0.8125rem;
  color: #64748B;
}

/* Field Styles */
.inputSection {
  margin-top: 1.5rem;
}

.fieldLabel {
  font-size: 13px;
  font-weight: 500;
  color: var(--text-secondary);
  margin-bottom: 0.5rem;
}

.required {
  color: var(--error);
  margin-left: 4px;
}

.inputWrapper {
  position: relative;
}

.input {
  width: 100%;
  height: 38px;
  padding: 0 12px;
  border: 1px solid var(--border);
  border-radius: 8px;
  font-size: 14px;
  color: var(--text);
  transition: 0.2s ease;
  box-sizing: border-box;
}

.input:hover {
  border-color: var(--primary);
}

.input:focus {
  outline: none;
  border-color: var(--primary);
  box-shadow: 0 0 0 3px rgba(79, 99, 190, 0.08);
}

.inputError {
  border-color: var(--error);
}

.inputError:hover,
.inputError:focus {
  border-color: var(--error);
  box-shadow: 0 0 0 3px rgba(239, 68, 68, 0.08);
}

.errorMessage {
  position: absolute;
  left: 12px;
  bottom: -20px;
  font-size: 12px;
  color: var(--error);
}

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }

  .header {
    margin-bottom: 1rem;
  }

  .inputSection {
    margin-top: 1rem;
  }

  .inputWrapper {
    padding: 0;
  }
}