.IntegrationsSidebar {
  width: 17rem;
  background-color: #ffffff;
  border-right: 0.0625rem solid #eceef6;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  box-sizing: border-box;
}

.searchContainer {
  padding: 1.25rem 0.625rem 1.25rem 2rem;
  border-bottom: 0.125rem dotted #eceef6;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.375rem;
}

.searchContainer > svg {
  width: 1.125rem;
  height: 1.125rem;
}

.searchInput {
  border: none;
  outline: none;
  font-size: 1.125rem;
  color: #a7a7a7;
  width: 100%;
}

.searchInput::placeholder {
  color: #a7a7a7;
  opacity: 1;
}

.listHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 1.25rem 1.25rem 0 2rem;
  font-weight: 600;
  font-size: 0.875rem;
  color: #6b6b6b;
}

.listHeaderContent {
  display: flex;
  align-items: center;
  gap: 1.27rem;
}

.icon {
  font-size: 1rem;
  color: #6b6b6b;
  width: 1.75rem;
  height: 1.75rem;
}

.sectionTitle {
  font-weight: 600;
  font-size: 1.125rem;
  color: #6b6b6b;
}

.expandIcon {
  font-size: 1rem;
  color: #6b6b6b;
}

.filterList {
  list-style: none;
  padding: 1.25rem 0 1.25rem 0;
  margin: 0;
}

.listItem {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.25rem;
  font-size: 0.875rem;
  color: #333333;
  transition: background-color 0.2s;
  padding-left: 3.75rem;
  gap: 1rem;
  justify-content: flex-start;
}

.listItem:hover {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.5) -15.76%,
    rgba(233, 239, 247, 0.5) 62.68%
  );
}

.checkbox {
  position: relative;
  width: 1.125rem;
  height: 1.125rem;
  flex-shrink: 0;
  appearance: none;
  background-color: #fff;
  border: 0.125rem solid #dcdcdc;
  border-radius: 1rem;
  cursor: pointer;
  outline: none;
  transition:
    background-color 0.2s,
    border-color 0.2s;
}

.checkbox:checked {
  background-color: #4f63be;
  border-color: #4f63be;
}

.checkbox:checked::after {
  content: "";
  position: absolute;
  top: 40%;
  left: 50%;
  width: 0.375rem;
  height: 0.625rem;
  border: solid #fff;
  border-width: 0 0.125rem 0.125rem 0;
  border-radius: 0.0625rem;
  transform: translate(-50%, -50%) rotate(45deg);
}

.listItemText {
  font-size: 1rem;
  color: #6b6b6b;
  font-weight: 500;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkbox:checked + .listItemText {
  color: #4f63be;
}

.menu {
  padding-top: 0.3125rem;
}
