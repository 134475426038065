.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.mappingName {
  display: flex;
  align-items: center;
}

.rightSection {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.mappingSelector {
  min-width: 250px;
}

.editContainer,
.viewContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.mappingTitle {
  font-size: 1.25rem;
  font-weight: 600;
  color: #4F63BE;
  margin: 0;
}

.nameInput {
  font-size: 1.25rem;
  font-weight: 600;
  color: #4F63BE;
  padding: 0.25rem 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  min-width: 200px;
  background: white;
  transition: border-color 0.15s ease;
}

.nameInput:focus {
  outline: none;
  border-color: #4F63BE;
}

.iconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px !important;
  height: 36px !important;
  border: none;
  background: rgba(167, 172, 233, 0.1);
  color: #4F63BE;
  cursor: pointer;
  transition: all 0.2s ease;
}

.iconButton:hover {
  color: #4F63BE;
  background: #f3f4f6;
}