.actionButtons {
  display: flex;
  width: 100%;
  gap: 0.5rem;
  align-items: flex-end;
  justify-content: space-between;
}

.iconButton {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px !important;
  height: 36px !important;
  border: none;
  background: rgba(167, 172, 233, 0.1);
  color: #4F63BE;
  cursor: pointer;
  transition: all 0.2s ease;
}

.iconButton:hover {
  background: #4F63BE;
  border-color: #4F63BE;
  color: white;
  box-shadow: 0 4px 20px rgba(147, 152, 199, 1);
}

.iconButton:active {
  transform: translateY(1px);
}

.iconButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.icon {
  width: 18px;
  height: 18px;
}

.deleteButton {
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  background: rgba(167, 172, 233, 0.1);
  color: #4F63BE;
  cursor: pointer;
  transition: 0.2s ease;
  flex-shrink: 0;
}

.deleteButton:hover {
  background: #FEE2E2;
  color: #EF4444;
  box-shadow: 0 4px 20px rgba(147, 152, 199, 1);
}