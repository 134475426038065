.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.625rem 2rem 0.5rem 2rem;
    background: #fff;
    width: 100%;
    height: 96px;
    box-sizing: border-box;
    border-bottom: 0.0625rem solid #eceef6;
  }
  
  .titleSection {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .title {
    font-weight: 600;
    font-size: 1.5rem;
    color: #000000;
    margin-bottom: 0;
    margin-top: 0;
  }
  
  .breadcrumb {
    font-weight: 400;
    font-size: 0.875rem;
    color: #c4c4c5;
  }
  
  .searchSection {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
  
  .backButton {
    background-color: #7398c7;
    color: #ffffff;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .searchWrapper {
    position: relative;
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 12px;
    padding: 0.3rem 1.25rem;
    width: 19.6875rem;
    box-sizing: border-box;
  }
  
  .searchInput {
    width: 100%;
    border: none;
    outline: none;
    font-size: 0.875rem;
    color: #a7a7a7;
    background: transparent;
  }
  
  .searchInput::placeholder {
    color: #a7a7a7;
    opacity: 1;
  }
  
  .searchIcon {
    color: #a7a7a7;
    margin-right: 0.5rem;
  }
  