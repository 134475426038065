.fieldContainer {
    position: relative;
    width: 100%;
}

.label {
    font-size: 0.8125rem;
    color: #64748B;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-bottom: 0.375rem;
}

.optionsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.radioOption {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: #64748B;
    cursor: pointer;
    user-select: none;
    transition: 0.2s ease;
}

.radio {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    accent-color: #4F63BE;
    cursor: pointer;
    transition: 0.2s ease;
}

.radio:hover {
    filter: brightness(1.1);
}

.error {
    font-size: 0.75rem;
    color: #EF4444;
    margin-top: 0.25rem;
    display: flex;
    align-items: flex-start;
    gap: 0.25rem;
}