.fieldContainer {
    position: relative;
    width: 100%;
}

.label {
    font-size: 0.8125rem;
    color: #64748B;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-bottom: 0.375rem;
}

.error {
    font-size: 0.75rem;
    color: #EF4444;
    margin-top: 0.25rem;
    display: flex;
    align-items: flex-start;
    gap: 0.25rem;
}