.paramWrapper {
  min-width: 0;
}

.label {
  font-size: 0.8125rem;
  color: #64748B;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-bottom: 0.375rem;
}

.required {
  color: #EF4444;
}

.inputContainer {
  position: relative;
  width: 100%;
}

.input,
.dateInput {
  width: 100%;
  height: 2.375rem;
  padding: 0 0.75rem;
  border: 0.0625rem solid #E2E8F0;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  background: white;
  transition: 0.2s ease;
  box-sizing: border-box;
}

.datePickerContainer {
  position: relative;
  width: 100%;
}

.dateIcon {
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
  height: 1rem;
  color: #64748B;
  pointer-events: none;
}

.input:hover:not(.error),
.dateInput:hover:not(.error) {
  border-color: #4F63BE;
}

.input:focus:not(.error),
.dateInput:focus:not(.error) {
  outline: none;
  border-color: #4F63BE;
  box-shadow: 0 0 0 0.1875rem rgba(79, 99, 190, 0.08);
}

.error {
  border-color: #EF4444;
}

.error:hover,
.error:focus {
  border-color: #EF4444;
  box-shadow: 0 0 0 0.1875rem rgba(239, 68, 68, 0.08);
}

.errorMessage {
  display: flex;
  align-items: flex-start;
  gap: 0.25rem;
  font-size: 0.75rem;
  line-height: 1.3;
  color: #EF4444;
  background: white;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  margin-top: 0.25rem;
}

.errorIcon {
  flex-shrink: 0;
  stroke-width: 0.125rem;
  margin-top: 0.0625rem;
}