.dialogBox {
    display: inline-block;
    position: relative;
    background-color: white;
    border: 2px solid #e27ddd;
    border-radius: 12px;
    padding: 16px;
    font-size: 14px;
    line-height: 1.5;
    color: #000000;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    z-index: 1000; /* Ensures dialog is on top */
  }
  
  .content {
    padding-right: 10px;
  }
  
  .closeButton {
    position: absolute;
    top: 8px;
    right: 8px;
    background: none;
    border: none;
    font-size: 18px;
    color: #e27ddd;
    cursor: pointer;
    padding: 0;
  }
  
  .closeButton:hover {
    color: #ff66b2;
  }
  