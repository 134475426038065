.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.fieldsList {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  list-style: none;
  padding: 0;
  margin: 0;
}

.fieldsList > li {
  list-style: none;
  margin: 0;
  padding: 0;
  cursor: grab;
  touch-action: none;
}

.fieldsList > li:active {
  cursor: grabbing;
}

.addButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 2.25rem;
  height: 2.25rem;
  margin: 0 .7rem 0 auto;
  background: #4F63BE;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 4px 20px rgba(147, 152, 199, 0.25);
}

.addButton > svg {
  stroke-width: 4;
  height: 1rem;
  width: 1rem;
  stroke-linecap: square;
}

.addButton.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.addButton:hover:not(.disabled) {
  background: #3b4d9b;
  box-shadow: 0 4px 20px rgba(147, 152, 199, 1);
}

.addButton:active:not(.disabled) {
  transform: translateY(1px);
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.disabled:hover {
  transform: none;
  box-shadow: 0 4px 20px rgba(147, 152, 199, 0.25);
}

@media (max-width: 768px) {
  .container {
    gap: 0.75rem;
  }

  .fieldsList {
    gap: 0.75rem;
  }

  .addButton {
    width: 100%;
    margin-top: 0.75rem;
  }
}