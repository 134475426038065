.hamburger {
  position: fixed;
  top: 73px;
  left: 263px; /* Positioned right next to the sidebar */
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  z-index: 1001; /* Ensures it's above other content */
  transition: left 0.3s ease-in-out; /* Move with the sidebar */
}

/* When the sidebar is hidden, move the hamburger button */
.hamburger.hidden {
  left: 0; /* Moves to the left with the hidden sidebar */
}

.hamburger:hover {
  color: lightgray;
}


/* Sidebar */
.sidebar {
  /* top: 73px; */
  width: 263px;
  height: 100vh;
  /* background-color: #333; */
  color: white;
  position: fixed;
  left: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

/* Sidebar hidden state */
.sidebar.hidden {
  transform: translateX(-100%);
}


.dashboard-layout {
  /* display: flex; */
  height: 100vh;
  position: relative;
}
/* Content transition */
.content {
  transition: margin-left 0.3s ease-in-out;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  padding: 20px;
}
