.nodeContainer {
  padding: 0.5rem;
  background: #ffffff;
  border-radius: 23.22px;
  width: fit-content;
  min-width: 3rem;
  height: 3rem;
  display: flex;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative;
  font-size: 0.8rem;
  align-items: center;
  justify-content: center;
}

.builderNode {
  box-shadow: none;
}

.nodeContainer:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.nodeHandle {
  width: 0.5rem;
  height: 0.5rem;
  display: inline-block;
  position: absolute;
  z-index: 10;
  background-color: transparent;
  border: none;
}

.handleBottom {
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%);
  background: url(../../../assetts/images/arrow_down.svg) no-repeat center; /* Ensure path is correct */
}

.handleRight {
  right: -3px;
  top: 50%;
  transform: translateY(-50%);
  background: url(../../../assetts/images/arrow_right.svg) no-repeat center;
}

.handleTop {
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  background: url(../../../assetts/images/arrow_down.svg) no-repeat center; /* Double-check image path */
}

.handleLeft {
  left: -5px;
  top: 50%;
  transform: translateY(-50%);
  background: url(../../../assetts/images/arrow_right.svg) no-repeat center;
}

.nodeContent {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;
  color: #333;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  font-size: 0.5rem;
}


.label {
  font-weight: 500;
  font-size: 14px;
  color: black;
}

.nodeIcon {
  height: 3rem;
  width: 3rem;
}

.wrapper {
  background-color: transparent;
}

.removeButton {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border: 1px solid #7398C7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  color: #7398C7;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  transform: translate(297%, 80%) !important;
}

.removeButton:hover {
  background-color: #ff4d4f;
  border: 1px solid #ff4d4f;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.removeButton:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}