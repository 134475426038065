.container {
  min-height: 100vh;
  background-color: #f9fafb;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.wrapper {
  width: 100%;
  max-width: 28rem;
}

.iconContainer {
  text-align: center;
  margin-bottom: 2rem;
}

.iconWrapper {
  display: inline-flex;
  padding: 1rem;
  background-color: white;
  border-radius: 9999px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem;
}

.icon {
  width: 2rem;
  height: 2rem;
  color: #3b82f6;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
  margin: 0;
}

/* Status-specific styles */
.verifying .icon {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.success .icon {
  color: #22c55e;
}

.error .icon {
  color: #ef4444;
}

/* Animation keyframes */
@keyframes pulse {
  0%, 100% {
      opacity: 1;
  }
  50% {
      opacity: 0.5;
  }
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .wrapper {
      max-width: 100%;
      padding: 0 1rem;
  }

  .title {
      font-size: 1.25rem;
  }
}