.actionsHeader {
  padding-top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.95);
}

.searchContainer {
  padding: 1.25rem 0.625rem 1.25rem 2rem;
  border-bottom: 0.125rem dotted #eceef6;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.375rem;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 2;
  width: 88%;
}

.searchIcon {
  width: 1.125rem;
  height: 1.125rem;
  color: #888888;
  transition: color 0.2s ease;
}

.searchInput {
  border: none;
  outline: none;
  font-size: 1.125rem;
  color: #a7a7a7;
  width: 100%;
  transition: color 0.3s ease;
  background: transparent;
}

.searchInput:focus {
  color: #333333;
}

.searchInput:focus + .searchIcon {
  color: #4f63be;
}


.resultCount {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.75rem;
  color: #64748b;
}

.bulkActions {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.selectAllLabel {
  cursor: pointer;
  user-select: none;
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 4px;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.checkbox:hover {
  background: rgba(0, 0, 0, 0.05);
}

.checkIcon {
  color: #3b82f6;
}

.actions {
  display: flex;
    gap: 0.5rem;
    padding: .8rem;
    opacity: 0.5;
    transition: opacity 0.3s ease;
    align-items: center;
    justify-content: space-between;
}

.actions.active {
  opacity: 1;
}

.actionButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  background: #f1f5f9;
  color: black;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
  height: 2rem;
}

.actionButton:hover:not(:disabled) {
  background: #e2e8f0;
}

.actionButton:disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

.actionButton.deleteButton {
  background: #fee2e2;
  color: #ef4444;
}

.actionButton.deleteButton:hover:not(:disabled) {
  background: #fecaca;
}
