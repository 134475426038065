.DetailViewPage {
  display: flex;
  flex-direction: column;
  background-color: #f5f9ff;
  margin-top: 4.5rem;
  overflow: hidden;
}

.headerAndContent {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.05);
  margin: 0;
  height: calc(100vh - 4.5rem);
}

.mainContent {
  display: flex;
  flex-grow: 1;
}

.filterSidebar {
  width: 16.25rem;
  background-color: #ffffff;
  border-right: 0.0625rem solid #eceef6;
  box-shadow: inset 0 -0.0625rem 0 #eceef6;
  overflow-y: auto;
}

.contentContainer {
  flex-grow: 1;
  display: flex;
  box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.05);
  padding: 0 16px;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 2000px) {
  .headerAndContent {
    height: calc(100vh - 4.5rem);
  }
}
