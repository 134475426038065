.sidebar {
  width: 19.375rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 1rem 2rem;
  box-sizing: border-box;
  overflow-y: auto;
  border-right: 0.0625rem solid #eceef6;
}

.sidebarEmptyState {
  padding: 0 !important;
  padding-top: 3rem !important;
  justify-content: flex-start;
}

.sidebarEmptyState > span,p {
  font-size: 12px;
}

.filterSection {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.filterItem {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.filterHeader {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 10px;
}

.filterIcon {
  width: 1.25rem;
  height: 1.25rem;
  color: #6b6b6b;
}

.filterTitle {
  font-weight: 400;
  font-size: 1rem;
  color: #6b6b6b;
}

.dateInput {
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 0.5rem;
  font-size: 14px;
  font-weight: 600;
  color: #6b6b6b;
  width: 100%;
  max-width: 261px;
  text-align: center;
  outline: none;
  letter-spacing: 1px;
  box-sizing: border-box;
}

.datePickerContainer {
  display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    max-width: 261px;
    flex-direction: column;
    z-index: 2;
}

.dateSeparator {
  font-size: 0.875rem;
  color: #6b6b6b;
  text-align: center;
  width: 100%;
  text-align: center;
}

.statusDropdown {
  position: relative;
  width: 100%;
}

.dropdown {
  width: 100%;
  height: 53px;
  padding: 0.5rem;
  background-color: #ffffff;
  border: 1px solid #e1e3e8;
  border-radius: 8px;
  font-size: 0.875rem;
  color: #333333;
  appearance: none;
  outline: none;
  cursor: pointer;
}

.dropdown option {
  color: #6b6b6b;
  font-size: 18.97px;
  font-weight: 500;
  line-height: 22.96px;
  letter-spacing: 0.01em;
  text-align: left;
}

.dropdownIcon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 1rem;
  color: #6b6b6b;
}

.listSection {
  flex-grow: 1;
  overflow-y: auto;
  padding-left: 0.6rem;
}

.logList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.logItem:hover {
  background-color: #f0f4ff;
}

.logDate {
  font-weight: 500;
  color: #4f63be;
}

.logTime {
  font-weight: 400;
  color: #333333;
}

.statusIcon {
  color: #eb5757;
  font-size: 1rem;
  margin-left: 36px;
}

.dateTime {
  display: flex;
}

.logItem {
  position: relative;
  padding-left: 20px;
  display: flex;
  align-items: center;
  margin-left: 0;
  height: 43px;
  gap: 0.5rem;
  justify-content: space-between;
}

.dateTime > span {
  font-size: 14px;
  color: #6b6b6b;
  letter-spacing: 1%;
  line-height: 19.36px;
  font-weight: 400;
  white-space: nowrap;
}

.logItem::before {
  content: "";
  position: absolute;
  left: 0;
  top: 6%;
  height: 28px;
  width: 14px;
  background-color: transparent;
  /* border-left: 2px solid var(--border-color, #C4C4C5); */
  border-bottom: 2px solid var(--border-color, #c4c4c5);
  border-radius: 0 0 0 16px;
  transform: translateY(-40%);
  /* z-index: 999; */
}

.logItem:first-child::before {
  border-top: none;
}

.logItem:not(:last-child)::after {
  content: "";
  position: absolute;
  left: 0;
  top: 27px;
  bottom: -44px;
  border-left: 2px solid #c4c4c5;
  transform: translateY(-50%);
}

.highlighted {
  color: #4f63be !important;
  font-weight: 600 !important;
}

/* Status-specific border and text color classes */
.successBorder {
  border-color: #62e4b5;
}

.errorBorder {
  border-color: #ef6a6a;
}

.pendingBorder {
  border-color: #e4c554;
}

.warningBorder {
  border-color: #e27ddd;
}

/* Update date and time styles based on status */
.successText {
  color: #62e4b5;
}

.errorText {
  color: #ef6a6a;
}

.pendingText {
  color: #e4c554;
}

.warningText {
  color: #e27ddd;
}



/* new */
/* Loading State */
.loadingState {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: #6B7280;
  padding: 2rem;
}

.loadingIcon {
  animation: spin 1s linear infinite;
}

/* List Section */
.listSection {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-left: 0.6rem;
}

.logList {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* Pagination */
.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-top: 1px solid #ECEEF6;
  background: white;
  margin-top: auto;
}

.paginationButtons {
  display: flex;
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
}

.paginationInfo {
  min-width: 1.5rem;
  text-align: center;
  color: #6B7280;
  font-size: 0.875rem;
  font-weight: 500;
}

.paginationButton {
  display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    padding: 0;
    border: 1px solid #E5E7EB;
    background: #4F63BE;
    color: white;
    cursor: pointer;
    transition: all 0.2s ease;
}

.paginationButton:disabled {
  
  color: #D1D5DB;
  border-color: #E5E7EB;
  cursor: not-allowed;
  pointer-events: none;
}

.paginationButton:hover:not(:disabled) {
  background: #1730a0;
  border-color: #4F63BE;
}

/* Animation */
@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Update existing styles */
.logItem {
  position: relative;
  padding-left: 20px;
  display: flex;
  padding-right: 0.5rem;
  align-items: center;
  margin-left: 0;
  height: 43px;
  gap: 0.5rem;
  justify-content: space-between;
  transition: transform 0.2s ease;
  cursor: pointer;
}

