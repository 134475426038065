.fieldContainer {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    width: 100%;
}

.label {
    font-size: 0.8125rem;
    color: #64748B;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-bottom: 0.375rem;
}

.checkboxGroup {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.checkboxLabel {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    color: #64748B;
    font-weight: 500;
    gap: 0.5rem;
}

.checkbox {
    width: 1rem;
    height: 1rem;
    border-radius: 0.375rem;
    border: 0.0625rem solid #E2E8F0;
    accent-color: #4F63BE;
    cursor: pointer;
    margin-right: 0.5rem;
    transition: 0.2s ease;
}

.checkbox:hover {
    border-color: #4F63BE;
}

.error {
    color: #EF4444;
    font-size: 0.75rem;
    margin-top: 0.25rem;
    display: flex;
    align-items: flex-start;
    gap: 0.25rem;
}