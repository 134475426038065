/* DropdownItem.module.css */
.item {
  padding: 0.625rem 1rem;
  font-size: 0.875rem;
  color: #374151;
  cursor: pointer;
  transition: background-color 0.15s ease;
  border-bottom: 0.0625rem solid #f3f4f6;
  line-height: 1.5;
  background: #ffffff;
  display: flex;
  flex-direction: column;
}

/* Combined border radius styles */
.item:first-child { border-radius: 0.5rem 0.5rem 0 0; }
.item:last-child { 
  border-bottom: none; 
  border-radius: 0 0 0.5rem 0.5rem; 
}

/* Hover states */
.item:not(.disabled):hover { background: #f8fafc; }
.item.parent:not(.disabled):hover { background: #f1f5f9; }

/* Selected state */
.item.selected:not(.disabled) {
  background: #eff6ff;
  color: #4F63BE;
  font-weight: 500;
}

/* Disabled states */
.item.disabled {
  background: #f3f4f6;
  cursor: not-allowed;
}

.item.disabled.selected {
  color: #9ca3af;
  font-weight: normal;
}

/* Content layout */
.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.label {
  flex: 1;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disabledText {
  color: #9ca3af;
}

/* Icon styles */
.icon { 
  width: 1rem; 
  height: 1rem; 
  transition: transform 0.2s ease; 
  color: #6b7280; 
}

.icon.expanded { 
  transform: rotate(90deg); 
}

.icon.disabledIcon {
  color: #9ca3af;
}

.nested { 
  width: 100%; 
}