.tableWrapper {
  border: 1px solid #E2E8F0;
  border-radius: 8px;
  width: 100%;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.headerRow {
  background-color: rgba(79, 99, 190, 0.05);
}

.headerCell {
  padding: 1rem;
  text-align: left;
  font-weight: 500;
  font-size: 0.75rem;
  color: #64748b;
  white-space: nowrap;
}

.arrowCell {
  width: 2rem;
  padding: 0.75rem 0.5rem;
  text-align: center;
}

.dataRow {
  transition: background-color 0.2s;
}

.dataRow:hover {
  background-color: #F8FAFC;
}

.cell {
  padding: 1rem;
}

.actions {
  width: 12rem;
}

.mappingName {
  font-size: 0.875rem;
    color: #4F63BE;
    padding: 0.25rem 0.75rem;
    background-color: rgba(79, 99, 190, 0.1);
    border-radius: 6px;
    display: flex;
    height: 2.25rem;
    box-sizing: border-box;
    white-space: nowrap;
    align-items: center;
}

.input {
  width: 100%;
  height: 2.25rem;
  padding: 0 0.75rem;
  border: 1px solid rgba(79, 99, 190, 0.2);
  border-radius: 8px;
  color: #6B6B6B;
  font-size: 0.875rem;
  box-sizing: border-box;
  transition: all 0.2s ease;
}

.input:hover {
  border-color: #4F63BE;
}

.input:focus {
  border-color: #4F63BE;
  box-shadow: 0 0 0 3px rgba(79, 99, 190, 0.1);
  outline: none;
}

.input:disabled {
  background: #f8fafc;
}

.arrowIcon {
  color: #94a3b8;
}

.actionsCell {
  padding: 0.75rem 1rem;
}

.actionsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.mapButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 2rem;
  padding: 0 1.25rem;
  background: #4F63BE;
  color: white;
  border: none;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.2s ease;
}

.mapButton:hover:not(.disabled) {
  background: #3b4d9b;
  box-shadow: 0 4px 20px rgba(147, 152, 199, 1);
}

.mapButton:active:not(.disabled) {
  transform: translateY(1px);
}

.mapButton.disabled {
  background: #e2e8f0;
  color: #94a3b8;
  cursor: not-allowed;
  opacity: 0.7;
  pointer-events: none;
}

.removeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border: none;
  background: rgba(167, 172, 233, 0.1);
  color: #4F63BE;
  cursor: pointer;
  transition: all 0.2s ease;
}

.removeButton:hover {
  background: #FEE2E2;
  color: #EF4444;
  box-shadow: 0 4px 20px rgba(147, 152, 199, 1);
}

.removeButton:active {
  transform: translateY(1px);
}

.addButtonWrapper {
  padding-right: 1rem;
}

.addButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 2rem;
  height: 2rem;
  margin: 0 0 0 auto;
  background: #4F63BE;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.addButton > svg {
  stroke-width: 4;
  height: 1rem;
  width: 1rem;
  stroke-linecap: square;
}

.addButton.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.addButton:hover:not(.disabled) {
  background: #3b4d9b;
  box-shadow: 0 4px 20px rgba(147, 152, 199, 1);
}

.addButton:active:not(.disabled) {
  transform: translateY(1px);
}

.targetNode , .sourceNode {
  width: 34%;
}

@media (max-width: 1024px) {
  .actionsWrapper {
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  }
  
  .mapButton {
    flex: 0 0 auto;
  }
}

@media (max-width: 768px) {
  .cell {
    padding: 0.75rem;
  }
  
  .headerCell {
    padding: 0.75rem;
  }
  
  .arrowCell {
    display: none;
  }
}