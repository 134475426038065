.tooltipContainer {
  position: relative;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  transform: translateX(-50%);
  background-color: #6F6E73;
  color: #FFFFFF;
  padding: 8px 12px;
  border-radius: 12px;
  font-size: 14px;
  white-space: nowrap;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 1;
  z-index: 9999;
}

.tooltipArrow {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #6F6E73;
}
