.inputWrapper,
.MultipleInputWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  width: 100%;
  box-sizing: border-box;
}

.inputWrapper *,
.MultipleInputWrapper * {
  box-sizing: border-box;
}

.inputWrapper label,
.MultipleInputWrapper label {
  font-size: 0.8125rem;
  color: #64748B;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.inputField {
  width: 100%;
  height: 2.375rem;
  padding: 0 0.75rem;
  border: 0.0625rem solid #E2E8F0;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  color: #1E293B;
  background: white;
  transition: all 0.2s ease;
}

.inputField:hover {
  border-color: #4F63BE;
}

.inputField:focus {
  outline: none;
  border-color: #4F63BE;
  box-shadow: 0 0 0 0.1875rem rgba(79, 99, 190, 0.08);
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  box-sizing: border-box;
  align-items: flex-end;
}

.fieldContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
}

.inputField {
  width: 100%;
  height: 2.375rem;
  padding: 0 0.75rem;
  border: 0.0625rem solid #E2E8F0;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  color: #1E293B;
  background: white;
  transition: all 0.2s ease;
}

.removeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  border: none;
  background: rgba(167, 172, 233, 0.1);
  color: #4F63BE;
  cursor: pointer;
  transition: all 0.2s ease;
}

.removeButton:hover {
  background: rgba(239, 68, 68, 0.08);
  border-color: #EF4444;
  color: #EF4444;
}

/* Error state */
.inputField.error {
  border-color: #EF4444;
}

.inputField.error:hover,
.inputField.error:focus {
  border-color: #EF4444;
  box-shadow: 0 0 0 0.1875rem rgba(239, 68, 68, 0.08);
}

.errorMessage {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-size: 0.75rem;
  color: #EF4444;
  margin-top: 0.25rem;
}

/* If remove button is present, add right padding to input */
.inputField.hasRemoveButton {
  padding-right: 2rem;
}