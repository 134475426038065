/* buttonedge.module.css */
.edge-container {
  cursor: pointer;
}

.edge-delete-button {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border: 1px solid #7398C7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  color: #7398C7;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.edge-delete-button:hover {
  background-color: #ff4d4f;
  border: 1px solid #ff4d4f;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

.edge-delete-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
}