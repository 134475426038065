.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 400px;
  background: white;
  box-sizing: border-box;
}

.headerSection {
  background: rgb(252, 253, 255);
  padding: 1rem 2rem;
  box-shadow: 0 4px 20px rgba(147, 152, 199, 1);
  position: sticky;
  top: 0;
  z-index: 10;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.mainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-top: 2.5rem;
  overflow: hidden;
  background: rgb(252, 253, 255);
}

.mappingContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  padding-right: 0.5rem;
}

@media (max-width: 768px) {
  .headerSection {
    padding: 0.75rem;
  }

  .headerContent {
    flex-direction: column;
    align-items: stretch;
    gap: 0.75rem;
  }

  .mainContent {
    padding: 0.75rem;
  }

  .mappingContainer {
    gap: 0.75rem;
  }
}