.formContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
}

.formSection {
    color: #4F63BE;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
}

.formSection > h2 {
    margin: 0;
}

.formContainer > h2, p {
    margin: 0;
}

.gridContainer {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr)); 
    width: 100%;
    gap: 1rem;
}

.fields {
    display: grid;
    width: 100%;
    gap: 1rem;
    box-sizing: border-box;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
}

.fieldItem {
    width: 100%;
    box-sizing: border-box;
}

.fieldGroup {
    gap: 0.5rem;
    grid-column: span 2;
    border-radius: 8px;
    display: flex;
    width: 100%;
    padding: .5rem;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
    box-sizing: border-box;
    background: rgba(167, 172, 233, 0.05);
}

.groupTitle {
    font-size: 1rem;
    font-weight: 600;
    color: #4F63BE;
    margin-bottom: 1rem;
}

.buttonGroupContainer {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    margin-top: 2rem;
    width: 100%;
    border-top: 1px solid #E2E8F0;
    padding-top: 1.5rem;
}

.buttonGroup {
    display: flex;
    gap: 1rem;
}

@media (min-width: 1440px) {
    .formContainer {
        height: calc(100vh - 12.5rem);
    }
}
