.NotificationsSidebar {
  display: flex;
  flex-direction: column;
  max-width: 24rem;
  width: 70%;
  height: 100%;
  background: #ffffff;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
}

/* Glass effect background */
.NotificationsSidebar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: -1;
}

@media (max-width: 1020px) {
  .NotificationsSidebar {
    width: 80%;
  }
}



