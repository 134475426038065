.automationFlowContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.buttonContainer {
  display: flex;
  gap: 1rem; 
}

.dateTime {
  font-weight: 600;
  color: #000;
  font-size: 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.cancelButton, .actionButton {
  background: #7398c7;
  color: #ffffff;
  border-radius: 24px;
  display: flex;
  border: none;
  padding: 8px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  letter-spacing: 0.01rem;
  gap: 12px;
  width: fit-content;
  height: 32px;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.cancelButtonText, .actionButton span {
  font-size: 14px;
}

.flowDiagram {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(115, 152, 199, 0.7);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 10px 10px rgba(115, 152, 199, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(115, 152, 199, 0);
  }
}
.actionButton {
  background-color: #7398c7; /* Initial background color */
  color: #ffffff;
  border-radius: 24px;
  display: flex;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.01rem;
  gap: 8px;
  width: fit-content;
  height: 32px;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.actionButton:hover {
  background-color: #5678a1; /* Darker shade when hovered */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

