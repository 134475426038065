.wrapper {
  width: 100%;
}

.row {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  transition: 0.2s ease;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
}

.leftContent {
  display: flex;
  gap: 1rem;
  flex: 1;
}

.indexCell {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1.75rem;
  height: 1.75rem;
  background: #F8FAFC;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: #64748B;
  margin-top: 1.625rem;
  flex-shrink: 0;
}

.functionCell {
  width: 20rem;
  flex-shrink: 0;
  min-width: 0;
}

.dropdownWrapper {
  position: relative;
  width: 100%;
}

.label {
  font-size: 0.8125rem;
  color: #64748B;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-bottom: 0.375rem;
}

.required {
  color: #EF4444;
}

.errorField {
  border-color: #EF4444 !important;
}

.errorField:hover,
.errorField:focus {
  border-color: #EF4444 !important;
  box-shadow: 0 0 0 0.1875rem rgba(239, 68, 68, 0.08) !important;
}

.errorMessage {
  position: absolute;
  top: calc(100% + 0.25rem);
  left: 0;
  right: 0;
  display: flex;
  align-items: flex-start;
  gap: 0.25rem;
  font-size: 0.75rem;
  line-height: 1.3;
  background: white;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  color: #EF4444;
  font-weight: 500;
  z-index: 10;
}

.errorIcon {
  flex-shrink: 0;
  stroke-width: 0.125rem;
  margin-top: 0.0625rem;
}

.parameterCells {
  min-width: 0;
  display: flex;
  gap: 1rem;
  flex: 1;
}

.deleteCell {
  flex-shrink: 0;
  margin-top: 1.45rem;
}

.deleteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.375rem;
  height: 2.375rem;
  border: none;
  background: rgba(167, 172, 233, 0.1);
  color: #4F63BE;
  cursor: pointer;
  transition: all 0.2s ease;
}

.deleteButton:hover:not(:disabled) {
   background: #FEE2E2;
  color: #EF4444;
  box-shadow: 0 4px 20px rgba(147, 152, 199, 1);
}

.deleteButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.chevron {
  color: #64748B;
}

/* Responsive styles */
@media (max-width: 75rem) { /* 1200px */
  .functionCell {
    width: 9.375rem;
  }

  .parameterCells {
    grid-template-columns: repeat(auto-fit, minmax(11.25rem, 1fr));
  }
}

