.workflowLibraryPage {
  display: flex;
  flex-direction: column;
  background-color: #f5f9ff;
  margin-top: 4rem;
}

.headerAndContent {
  display: flex;
  flex-direction: column;
  height: 95vh;
  background-color: #ffffff;
}

.mainContent {
  display: flex;
  flex-grow: 1;
}

.filterSidebar {
  width: 16.25rem;
  background-color: #ffffff;
  border-right: 0.0625rem solid #eceef6;
  box-shadow: inset 0 -0.0625rem 0 #eceef6;
  overflow-y: auto;
}

.contentContainer {
  flex-grow: 1;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.625rem rgba(0, 0, 0, 0.05);
  overflow-y: scroll;
  position: relative;
  height: 100%;
  background: #ffffff;
}

.contentContainer::-webkit-scrollbar {
  width: 8px;
}

.contentContainer::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
}

.contentContainer::-webkit-scrollbar-thumb {
  background-color: #eceef6;
  border-radius: 10px;
}

.contentContainer::-webkit-scrollbar-thumb:hover {
  background: #eceef6;
}
