.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.625rem 2rem 0.5rem 2rem;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #eceef6;
  border-radius: 10px; /* To match the curved corners as seen in the image */
}

.titleSection {
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: 600;
  font-size: 1.25rem; /* Adjusted size */
  color: #000000;
  margin: 0;
  margin-bottom: 0.5rem; /* Space between title and breadcrumb */
}

.breadcrumb {
  font-weight: 400;
  font-size: 0.875rem;
  color: #888888; /* Adjust color to match the design */
}

.actionsSection {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Space between buttons */
}

.backButton {
  background-color: #7398c7;
  color: #ffffff;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.actionButton {
  background-color: #f2f2f2;
  color: #4f63be;
  border: none;
  border-radius: 24px;
  padding: 0.5rem 1rem;
  font-size: 12.68px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.actionButton:hover {
  background-color: #e0e0e0; /* Slight hover effect */
}
