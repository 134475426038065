.headerContainer {
  background: rgb(252, 253, 255);
  padding: 1rem 2rem;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0 4px 20px rgba(147, 152, 199, 1);
}

.mainContent {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
}

.actionsGroup {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.historyActions {
  display: flex;
  gap: 0.375rem;
  padding-right: 1.5rem;
  border-right: 1px solid rgba(167, 172, 233, 0.2);
}

.mainActions {
  display: flex;
  align-items: center;
  gap: 0.875rem;
}

.iconButton {
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(167, 172, 233, 0.1);
  border: none;
  color: #4F63BE;
  cursor: pointer;
  transition: all 0.2s ease;
}

.iconButton:hover:not(:disabled) {
  background: #4F63BE;
  color: white;
  box-shadow: 0 4px 20px rgba(147, 152, 199, 1);
}

.iconButton:active:not(:disabled) {
  transform: translateY(1px);
}

.iconButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.secondaryButton {
  height: 2.25rem;
  padding: 0 1.125rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: rgba(167, 172, 233, 0.1);
  border: none;
  color: #4F63BE;
  font-weight: 500;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.addBotton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 2.25rem;
  height: 2.25rem;
  background: #4F63BE;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.addBotton > svg {
  stroke-width: 4;
  height: 1rem;
  width: 1rem;
  stroke-linecap: square;
}

.secondaryButton:hover {
  background: #FEE2E2;
  color: #EF4444;
  box-shadow: 0 4px 20px rgba(147, 152, 199, 1);
}

.secondaryButton:active {
  transform: translateY(1px);
}

.primaryButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 2.25rem;
  padding: 0 1.125rem;
  background: #4F63BE;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.addButton {
  width: 2.25rem;
}

.addButton > svg {
  stroke-width: 4;
  height: 1rem;
  width: 1rem;
  stroke-linecap: square;
}

.primaryButton:hover:not(:disabled) {
  background: #4F63BE;
  box-shadow: 0 4px 20px rgba(147, 152, 199, 1);
}

.addBotton:hover:not(:disabled) {
  background: #4F63BE;
  box-shadow: 0 4px 20px rgba(147, 152, 199, 1);
}
.primaryButton:active:not(:disabled) {
  transform: translateY(1px);
}

.primaryButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

@media (max-width: 1024px) {
  .mainContent {
    justify-content: flex-end;
  }
}