.button {
    background-color: #f2f2f2;
    color: #4f63be;
    border: none;
    border-radius: 24px;
    padding: 0.5rem 1rem;
    font-size: 12.68px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.primary {
    background-color: #4F63BE;
    color: #fff;
}

.secondary {
    background-color: #6c757d;
    color: #fff;
}

.warning {
    background-color: #ffc107;
    color: #212529;
}
