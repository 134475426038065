.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: clamp(1rem, 2vw, 1.625rem) clamp(1rem, 2vw, 2rem);
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #eceef6;
  border-radius: 10px;
  gap: clamp(0.75rem, 2vw, 1.5rem);
}

.titleSection {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 0; /* Prevents flex item from overflowing */
  flex: 1;
}

.row {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  min-width: 0; /* Prevents flex item from overflowing */
}

.title {
  font-weight: 600;
  font-size: clamp(1rem, 2vw, 1.25rem);
  color: #000000;
  margin: 0;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
}

.titleInput {
  font-weight: 600;
  font-size: clamp(1rem, 2vw, 1.25rem);
  color: #000000;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  width: 100%;
  min-width: 0;
  border-bottom: 1px solid #4f63be;
}

.editIcon {
  cursor: pointer;
  width: clamp(14px, 1.5vw, 16px);
  height: auto;
  flex-shrink: 0;
}

.breadcrumb {
  font-weight: 400;
  font-size: clamp(0.75rem, 1.5vw, 0.875rem);
  color: #888888;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.actionsSection {
  display: flex;
  align-items: center;
  gap: clamp(0.25rem, 1vw, 0.5rem);
  flex-shrink: 0;
}

.backButton {
  background-color: #7398c7;
  color: #ffffff;
  border-radius: 50%;
  width: clamp(1.75rem, 3vw, 2rem);
  height: clamp(1.75rem, 3vw, 2rem);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
}

.actionButton {
  background-color: #f2f2f2;
  color: #4f63be;
  border: none;
  border-radius: 24px;
  padding: clamp(0.35rem, 1vw, 0.5rem) clamp(0.5rem, 1.5vw, 1rem);
  font-size: clamp(11px, 1.2vw, 12.68px);
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap;
  flex-shrink: 0;
}

.unit {
  height: clamp(16px, 2.5vw, 19px);
  border-radius: 10px;
  border: 1px solid #4f63be;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  box-sizing: border-box;
  flex-shrink: 0;
}

.unit > p {
  margin: 0;
  font-size: clamp(10px, 1.2vw, 11px);
  color: #4f63be;
  font-weight: 600;
}

.unit > span {
  font-size: clamp(10px, 1.2vw, 11px);
  color: #4f63be;
  margin-left: 4px;
}

/* Media Queries */
@media screen and (max-width: 1366px) {
  .headerContainer {
    flex-wrap: wrap;
  }

  .titleSection {
    min-width: 250px;
  }
}

@media screen and (max-width: 760px) {
  .headerContainer {
    padding: 1rem;
    flex-direction: column;
    align-items: stretch;
  }

  .actionsSection {
    overflow-x: auto;
    padding-bottom: 0.25rem;
    margin: 0 -0.5rem;
    padding: 0 0.5rem;
    -webkit-overflow-scrolling: touch;
  }

  .actionsSection::-webkit-scrollbar {
    height: 4px;
  }

  .actionsSection::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 4px;
  }
}

@media screen and (max-width: 480px) {
  .row {
    flex-wrap: wrap;
  }

  .title, .titleInput {
    width: calc(100% - 24px); /* Accounting for edit icon */
  }

  .unit {
    margin-left: 24px; /* Aligns with title when wrapped */
  }
}

/* Interaction States */
.actionButton:hover {
  background-color: #e0e0e0;
}

.backButton:hover {
  background-color: #5f7fad;
}

.actionButton:active,
.backButton:active {
  transform: scale(0.98);
}