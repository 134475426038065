.input-hidden-label input:not(:placeholder-shown) + label {
    display: none;
  }
  
  .input-hidden-label input:focus + label {
    display: block;
  }

  /* Custom CSS */
  .customTextFieldSignup .MuiInputBase-root {
    border-radius: 4px!important;
    background-color: #F2F2F2; /* Set your desired background color */
    height: 48px;
    border: 0;
    color:#1C1C1C;
  }
  .customTextFieldSignup .MuiOutlinedInput-root {
    border-radius: 0; /* Optional: to remove the rounded corners */
  }
  
  .customTextFieldSignup .MuiOutlinedInput-notchedOutline {
    border: none; /* This removes the border */
  }
  
  /* Optional: Customize hover and focus states */
  .customTextFieldSignup .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
  .customTextFieldSignup .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: none; /* Ensure no border on hover and focus */
  }

  
.customTextField .MuiInputBase-root {
  border-radius: 12px!important;
  background-color: #F2F2F2; /* Set your desired background color */
  height: 48px;
  border: 0;
  color:#1C1C1C;
}
.customTextField .MuiOutlinedInput-root {
  border-radius: 0; /* Optional: to remove the rounded corners */
}

.customTextField .MuiOutlinedInput-notchedOutline {
  border: none; /* This removes the border */
}

/* Optional: Customize hover and focus states */
.customTextField .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
.customTextField .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: none; /* Ensure no border on hover and focus */
}
.hidden-label .MuiFormLabel-root {
    visibility: hidden;
  }
  
  .hidden-label input:not(:placeholder-shown) + .MuiFormLabel-root {
    visibility: visible;
  }
.loginbutton{
  width: 466px;
  height: 48px;
  gap: 0px;
  border-radius: 12px 0px 0px 0px;
  background-color: #AAE7F0!important;
  color: #454545!important;
}
.text-style-signup{
  font-family: Inter;
  font-size: 2px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
}
.text-style{
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
}
.MuiInputAdornment-root > input {
  background-color: none !important;
}


