/* Container positioning */
.NotificationsDropdown {
  position: absolute;
  top: 4.5rem;
  right: 1rem;
  width: 20rem;
  background: white;
  border: 1px solid #ECEEF6;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 6rem);
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #F5F9FF;
  border-bottom: 1px solid #ECEEF6;
}

.headerLeft {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.title {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.bellIcon {
  color: #666;
}

.markAllButton {
  display: flex;
  align-items: center;
  gap: 0.375rem;
  padding: 0.5rem 0.75rem;
  border: none;
  color: #4F63BE;
  background: transparent;
  font-size: 0.875rem;
  cursor: pointer;
  transition: 0.2s ease;
}

.markAllButton:hover {
  color: #3D4F9F;
}

/* List */
.notificationsList {
  flex: 1;
  overflow-y: auto;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* States */
.loading, 
.emptyState {
  padding: 2rem 1rem;
  text-align: center;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.loadingIcon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Pagination */
.paginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;
  border-top: 1px solid #ECEEF6;
  background: #F5F9FF;
  gap: 0.5rem;
}

.paginationLink {
  display: flex;
  align-items: center;
  gap: 0.375rem;
  padding: 0.5rem 0.75rem;
  background: transparent;
  border: none;
  color: #4F63BE;
  font-size: 0.875rem;
  cursor: pointer;
}

.paginationLink:hover {
  color: #3D4F9F;
  text-decoration: underline;
}

.buttonSpacer {
  color: #ECEEF6;
}