/* AppearanceSettings.module.css */

.outerBox {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-x: auto;
  padding: 1rem 2rem;
  transition: all 0.2s ease;
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(414px, 1fr));
  gap: 1rem;
}

.inputWrapper {
  display: flex;
  gap: .5rem;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;

}

.inputField {
  height: 36px;
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.875rem;
  width: 100%;
  transition: all 0.2s ease;
  box-sizing: border-box;
  background: white;
}

.inputField:hover {
  border-color: #4F63BE;
}

.inputField:focus {
  outline: none;
  border-color: #4F63BE;
  box-shadow: 0 2px 4px rgba(79, 99, 190, 0.1);
}

.iconOption {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #7398C7;
}

.inputLabel {
  font-size: 0.75rem;
  font-weight: 500;
  color: #64748b;
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.2;
}

.buttonGroup {
  display: flex;
  gap: 1rem;
}

.removeButton,
.addButton,
.toggleButton,
.toggleButtonActive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: none;
  border-radius: 6px;
  background: rgba(167, 172, 233, 0.1);
  cursor: pointer;
  transition: all 0.2s ease;
  flex-shrink: 0;
}

.removeButton {
  color: #b22;
}

.removeButton:hover {
  background: #b22;
  color: white;
  box-shadow: 0 4px 20px rgba(178, 34, 34, 0.2);
}

.addButton {
  color: #4F63BE;
}

.addButton:hover {
  background: #4F63BE;
  color: white;
  box-shadow: 0 4px 20px rgba(79, 99, 190, 0.2);
}

.toggleButton {
  color: #7398C7;
}

.toggleButton:hover {
  background: #4F63BE;
  color: white;
  box-shadow: 0 4px 20px rgba(79, 99, 190, 0.2);
}

.toggleButtonActive {
  background: #4F63BE;
  color: white;
}

.toggleButtonActive:hover {
  box-shadow: 0 4px 20px rgba(79, 99, 190, 0.2);
}

.leftWrap {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Media Queries */
@media screen and (max-width: 1280px) {
  .list {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 768px) {
  .inputWrapper {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }

  .buttonGroup {
    justify-content: flex-end;
  }
}