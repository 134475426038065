.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.blur {
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.dialog {
  background: white;
  border-radius: 16px;
  padding: 24px;
  position: relative;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
              0 10px 10px -5px rgba(0, 0, 0, 0.04);
  max-width: 95vw;
  margin: 16px;
  min-width: 300px;
}

/* Size variants */
.small { width: 400px; }
.medium { width: 500px; }
.large { width: 600px; }

/* Position variants */
.center { margin: auto; }
.top { margin-top: 5vh; }
.bottom { margin-bottom: 5vh; }

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeButton:hover:not(:disabled) {
  background-color: rgba(0, 0, 0, 0.05);
}

.closeButton:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.closeIcon {
  width: 20px;
  height: 20px;
  color: #6b7280;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.iconWrapper {
  margin-bottom: 20px;
  padding: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 32px;
  height: 32px;
}

.textContent {
  margin-bottom: 24px;
  width: 100%;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 8px;
}

.message {
  color: #6b7280;
  line-height: 1.5;
}

.actions {
  display: flex;
  gap: 12px;
  justify-content: center;
  width: 100%;
}

.cancelButton,
.confirmButton {
  padding: 10px 24px;
  border-radius: 24px;
  font-weight: 500;
  transition: all 0.2s ease;
  cursor: pointer;
  font-size: 0.875rem;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.loadingWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.loadingIcon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.cancelButton {
  background-color: #f3f4f6;
  color: #4b5563;
  border: 1px solid #e5e7eb;
}

.cancelButton:hover:not(:disabled) {
  background-color: #e5e7eb;
}

.confirmButton {
  color: white;
  border: none;
}

.confirmButton:disabled,
.cancelButton:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

/* Variant styles */
.dangerButton {
  background-color: #ef4444;
}

.dangerButton:hover:not(:disabled) {
  background-color: #dc2626;
}

.successButton {
  background-color: #22c55e;
}

.successButton:hover:not(:disabled) {
  background-color: #16a34a;
}

.warningButton {
  background-color: #f59e0b;
}

.warningButton:hover:not(:disabled) {
  background-color: #d97706;
}

.infoButton {
  background-color: #3b82f6;
}

.infoButton:hover:not(:disabled) {
  background-color: #2563eb;
}
