.fieldContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  color: grey;
}

.label {
  font-size: 0.8125rem;
  color: #64748B;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-bottom: 0.375rem;
}

.dashboardWrapper {
  border: 0.0625rem dashed #E2E8F0;
  border-radius: 0.375rem;
  padding: 1rem;
  background-color: rgba(167, 172, 233, 0.05);
  max-width: 22rem;
  text-align: center;
  color: #64748B;
}

.dashboardWrapper a {
  text-decoration: underline;
  transition: 0.2s ease;
}

.dashboardWrapper a:hover {
  color: #6C8AFF;
}

.uppy-Container {
  display: flex;
    justify-content: center;
}

.uppy-Dashboard-AddFiles {
  background-color: rgba(167, 172, 233, 0.05);

}

.error {
  color: #EF4444;
  font-size: 0.75rem;
  margin-top: 0.25rem;
  display: flex;
  align-items: flex-start;
  gap: 0.25rem;
}