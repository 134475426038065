/* Base styles */
.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.wrapper * {
  box-sizing: border-box;
  min-width: 0;
}

.fieldRow {
  position: relative;
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  gap: 2rem;
  padding: 1rem;
  background: white;
  border: 1px solid rgba(167, 172, 233, 0.2);
  border-radius: 8px;
  transition: all 0.2s ease;
  width: 100%;
}

.fieldRow:hover {
  border-color: #4F63BE;
  box-shadow: 0 2px 4px rgba(79, 99, 190, 0.1);
}

.dragHandle {
  display: flex;
  align-items: center;
  cursor: grab;
  color: #7398C7;
  flex-shrink: 0;
}

.fieldSelectors {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  min-width: 0;
}

.fieldPair {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: flex-start;
  width: 100%;
  min-width: 0;
}

.fieldPair > * {
  flex: 1;
  min-width: 0;
}

.fieldPair > :first-child {
  flex: 2;
}

.fieldContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  min-width: 0;
}

.fieldLabel {
  font-size: 0.75rem;
  color: #64748b;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mappingArrow {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7398C7;
  transform: rotate(0);
  align-self: center;
  padding-top: 1.6rem;
  flex-shrink: 0;
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: flex-end;
  width: 50rem;
  min-width: 0;
}

.actions > * {
  flex: 1;
  min-width: 0; 
}

.actions > :first-child {
  flex: 1.3;
}

.customInput {
  width: 100%;
  height: 36px;
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.875rem;
  min-width: 0;
}

.typeToggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: none;
  border-radius: 6px;
  background: rgba(167, 172, 233, 0.1);
  color: #4F63BE;
  cursor: pointer;
  transition: all 0.2s ease;
  flex-shrink: 0;
}

.typeToggle:hover {
  background: #4F63BE;
  box-shadow: 0 4px 20px rgba(147, 152, 199, 1);
  color: white;
}

.typeToggleActive {
  background-color: #4F63BE;
  color: white;
}

.typeToggleInactive {
  color: #4F63BE;
}

.typeToggleInactive:hover {
  color: white;
}

.fieldInputContainer {
  display: flex;
  gap: 0.5rem;
  width: 100%;
  height: 36px;
  min-width: 0;
}

/* Media Queries */
@media screen and (max-width: 1440px) {
  .fieldRow {
    gap: 1rem;
  }
  
  .fieldSelectors {
    gap: 1.5rem;
  }
  
  .fieldPair {
    gap: 1rem;
  }
}

@media screen and (max-width: 1280px) {
  .fieldPair {
    flex-direction: column;
  }
  
  .fieldPair > *, 
  .fieldPair > :first-child {
    flex: 1;
    width: 100%;
  }

  .actions {
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 24rem;
  }
}

@media screen and (max-width: 768px) {
  .fieldRow {
    gap: 1rem;
  }

  .fieldSelectors {
    flex-direction: row;
    align-items: stretch;
  }
  
  .mappingArrow {
    padding: 1rem 0;
    align-self: center;
  }
}