/* EmptyState.module.css */
.emptyState {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 48px;
    color: var(--text-secondary);
    text-align: center;
    gap: 12px;
    border-radius: 8px;
  }
  
  .emptyState p {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    color: var(--text-primary);
  }
  
  .emptyState span {
    font-size: 14px;
  }