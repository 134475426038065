/* WorkflowCard.module.css */
.card {
  word-wrap: break-word;
  background-clip: initial;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
  height: 24rem;
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
}

.reactFlowContainer {
  width: 100%;
  height: 100%;
}

.nodeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99;
}

@media (max-width: 2000px) {
  .card {
    height: 18rem;
  }
}
