.sidebarContainer {
  display: flex;
  flex-direction: column;
  border-right: 0.0625rem solid #eceef6;
  max-width: 21.875rem;
  height: 100vh;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
  width: 30%;
}

.sidebar {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  gap: 0.0625rem;
  align-items: stretch;
  height: calc(100vh - 15.375rem);
  position: relative;
}

.searchContainer {
  padding: 1.25rem 0.625rem 1.25rem 2rem;
  border-bottom: 0.125rem dotted #eceef6;
  display: flex;
  align-items: center;
  gap: 0.375rem;
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 10;
  height: 4.5rem;
  box-sizing: border-box;
}

.searchIcon {
  width: 1.125rem;
  height: 1.125rem;
  color: #4F63BE;
}

.searchInput {
  border: none;
  outline: none;
  font-size: 1.125rem;
  color: #6b6b6b;
  width: 100%;
  transition: all 0.2s ease;
}

.searchInput::placeholder {
  color: #a7a7a7;
  font-weight: 400;
  line-height: 1.36125rem;
  opacity: 1;
}

.searchInput:focus {
  color: #4F63BE;
}

.categoryHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-top: 0.0625rem solid #eceef6;
  max-height: 3.3125rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
  padding: 1rem 1.6875rem 1rem 3.1875rem;
}

.categoryHeader:hover {
  background-color: #f9f9f9;
}

.categoryIcon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  vertical-align: middle;
  object-fit: contain;
}

.categoryTitle {
  font-weight: 500;
  color: #6b6b6b;
  font-size: 1rem;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dropdownIcon {
  width: 1.375rem;
  height: 1.375rem;
  flex-shrink: 0;
  color: #7398C7;
  transition: transform 0.2s ease;
}

.expanded {
  transform: rotate(90deg);
}

.nodeList {
  position: relative;
  background-color: #F5F9FF;
  overflow: hidden;
}

.nodeItem {
  position: relative;
  padding: 0.75rem 1.5rem 0.75rem 4.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  color: #6b6b6b;
  background-color: #F5F9FF;
  cursor: grab;
  border-left: 0.1875rem solid transparent;
  user-select: none;
  transition: all 0.2s ease;
}

.nodeItem:hover {
  background-color: #4F63BE;
  color: white;
  border-left-color: #7398C7;
}

.nodeItem:active {
  cursor: grabbing;
  background-color: #394B99;
}

.nodeLabel {
  flex: 1;
  font-size: 0.875rem;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nodeIcon {
  width: 1.5rem;
  height: 1.5rem;
  background-color: #ffffff;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
}

.nodeIconImage {
  width: 1rem;
  height: 1rem;
  object-fit: contain;
}

.nodeItem:hover .nodeIcon {
  background-color: #ffffff;
  transform: scale(1.1);
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
}

/* Loading State */
.loading {
  opacity: 0.7;
  pointer-events: none;
}

/* Empty State */
.emptyState {
  padding: 1.5rem;
  text-align: center;
  color: #a7a7a7;
}

/* Media Queries */
@media screen and (max-width: 64rem) {
  .sidebar {
    width: 100%;
  }
}

@media screen and (max-width: 48rem) {
  .sidebarContainer {
    min-width: 15.625rem;
  }

  .searchContainer {
    padding: 1rem 0.5rem 1rem 1.5rem;
    height: 4rem;
  }

  .nodeItem {
    padding: 0.625rem 1rem 0.625rem 2.5rem;
  }
}

@media screen and (max-width: 30rem) {
  .sidebarContainer {
    min-width: 100%;
    max-width: 100%;
  }

  .nodeIcon {
    width: 1.25rem;
    height: 1.25rem;
  }

  .nodeIconImage {
    width: 0.875rem;
    height: 0.875rem;
  }

  .categoryHeader {
    padding: 0.875rem 1.25rem 0.875rem 2rem;
  }
}

/* Print styles */
@media print {
  .sidebarContainer {
    display: none;
  }
}