.fieldContainer {
    position: relative;
    width: 100%;
}

.label {
    font-size: 0.8125rem;
    color: #64748B;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    margin-bottom: 0.375rem;
}

.autocompleteInput {
    width: 100%;
    height: 2.375rem;
    padding: 0 0.75rem;
    border: 0.0625rem solid #E2E8F0;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    background: white;
    transition: 0.2s ease;
    box-sizing: border-box;
}

.autocompleteInput:hover:not(.error) {
    border-color: #4F63BE;
}

.autocompleteInput:focus:not(.error) {
    outline: none;
    border-color: #4F63BE;
    box-shadow: 0 0 0 0.1875rem rgba(79, 99, 190, 0.08);
}

.error {
    font-size: 0.75rem;
    color: #EF4444;
    margin-top: 0.25rem;
    display: flex;
    align-items: flex-start;
    gap: 0.25rem;
}