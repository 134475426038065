/* NodePropertiesModal.module.css */
.modalOverlay {
  height: 100%;
  width: 100%;
  max-height: calc(100vh - 5rem);
  background: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 0.25rem 1.5rem rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
}

.modalContent {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}

.sectionToggle {
  display: flex;
  justify-content: center;
  padding: 1rem;
  gap: 0.5rem;
  flex-wrap: wrap;
  align-items: center;
}

.toggleButton {
  background: #ffffff;
  color: #464649;
  font-size: 0.79375rem;
  padding: 0.5rem 0.5rem;
  font-weight: 600;
  line-height: 0.959375rem;
  letter-spacing: 0.01em;
  text-align: center;
  border: none;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  cursor: pointer;
}

.toggleButton.active {
  min-width: 6.8125rem;
  height: 2.0625rem;
  padding: 0.5625rem 0.5rem;
  border-radius: 1.5rem;
  background-color: rgba(167, 172, 233, 0.1);
  color: #4f63be;
  border: none;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-height: 0;
}

.sectionContent {
  flex: 1;
  overflow-y: auto;
}

.actionButtons {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: auto;
  border-top: 0.0625rem solid #ECEEF6;
  padding: 2rem;
}

.saveButton,
.cancelButton {
  min-width: 7.4375rem;
  height: 2rem;
  padding: 0.5rem 1rem;
  border-radius: 1.5rem;
  border: none;
  font-weight: 500;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  cursor: pointer;
}

.saveButton {
  background-color: #4f63be;
  color: white;
}

.saveButton:hover {
  background-color: #3b4ea5;
}

.cancelButton {
  background-color: #7398c7;
  color: white;
}

.cancelButton:hover {
  background-color: #6384b0;
}

.emptyState {
  text-align: center;
  color: #64666B;
  padding: 2rem;
  font-size: 0.9375rem;
}
