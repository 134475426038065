.tile {
  box-sizing: border-box;
  width: 100%;
  max-width: 20rem;
  min-width: 16rem;
  height: 9.375rem;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  transition: box-shadow 0.3s ease;
}

.tile:hover {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
}

.tileContent {
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  background: transparent;
}

.iconContainer {
  width: 2rem;
  height: 2rem;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.tileIcon {
  color: #4f63be;
  font-size: 1.5rem;
  width: 2rem;
  height: 2rem;
}

.title {
  flex: 1;
  font-size: 1rem;
  font-weight: 500;
  color: #000000;
  line-height: 1.375rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  cursor: pointer;
  z-index: 99;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f8f8;
  border-top: 1px solid #d9d9d9;
  border-radius: 0 0 0.5rem 0.5rem;
  height: 3.25rem;
}

.footerSection1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f8f8;
  border-radius: 0 0 0.5rem 0.5rem;
  padding: 0.5rem 1rem;
  width: 100%;
}

.footerSection2 {
  width: 2.5rem;
  height: 100%;
  display: flex;
  background-color: #AAE7F0;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 0.5rem 0;
}

.category {
  font-size: 0.875rem;
  color: #949494;
  line-height: 1.0625rem;
  max-width: 8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.actionIcon {
  color: #62d5e4;
  width: 0.703125rem;
  height: 0.9375rem;
}

.unit {
  width: auto;
  height: 19px;
  border-radius: 10px;
  border: 1px solid #4f63be;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  box-sizing: border-box;
}

.unit > p {
  margin: 0;
  font-size: 11px;
  color: #4f63be;
  font-weight: 600;
}

.unit > span {
  font-size: 11px;
  color: #4f63be;
  margin-left: 4px;
}
