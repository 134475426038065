.container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.contentSection {
    flex: 1;
    background: rgb(252, 253, 255);
    overflow-y: auto;
    padding: 1rem;
    padding-top: 2.5rem;
    box-shadow: 0 4px 20px rgba(147, 152, 199, 0.1);
}

.contentWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

/* Header styles */
.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1.5rem;
}

.headerContent {
    flex: 1;
}

.headerTitle {
    font-size: 0.9375rem;
    font-weight: 500;
    color: #1E293B;
    margin-bottom: 0.25rem;
    display: flex;
    align-items: center;
}

.headerSubtitle {
    font-size: 0.8125rem;
    color: #64748B;
}

.required {
    color: #EF4444;
    margin-left: 0.25rem;
}

.helpButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    border: none;
    background: rgba(79, 99, 190, 0.1);
    color: #4F63BE;
    cursor: pointer;
    transition: all 0.2s ease;
    flex-shrink: 0;
}

.helpButton:hover {
    background: #4F63BE;
    color: white;
    box-shadow: 0 4px 20px rgba(147, 152, 199, 0.2);
}

.helpButton:active {
    transform: translateY(1px);
}

/* Content styles */
.expressionsContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
}

.expressionRow {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.expressionInput {
    flex: 1;
    height: 2.375rem;
    padding: 0 0.75rem;
    border: 0.0625rem solid #E2E8F0;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    font-family: monospace;
    color: #1E293B;
    transition: all 0.2s ease;
}

.expressionInput:hover {
    border-color: #4F63BE;
}

.expressionInput:focus {
    outline: none;
    border-color: #4F63BE;
    box-shadow: 0 0 0 0.1875rem rgba(79, 99, 190, 0.08);
}

.expressionInput.error {
    border-color: #EF4444;
}

.expressionInput.error:hover,
.expressionInput.error:focus {
    border-color: #EF4444;
    box-shadow: 0 0 0 0.1875rem rgba(239, 68, 68, 0.08);
}

.deleteButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.375rem;
    height: 2.375rem;
    border: none;
    background: rgba(167, 172, 233, 0.1);
    color: #4F63BE;
    cursor: pointer;
    transition: 0.2s ease;
    flex-shrink: 0;
  }
  
  .deleteButton:hover {
    background: #FEE2E2;
    color: #EF4444;
    box-shadow: 0 4px 20px rgba(147, 152, 199, 1);
  }

.deleteButton:active {
    transform: translateY(1px);
}

.addButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.25rem;
    height: 2.25rem;
    margin: 0 0 0 auto;
    background: #4F63BE;
    border: none;
    color: white;
    cursor: pointer;
    transition: all 0.2s ease;
}

.addButton > svg {
    stroke-width: 2;
    height: 1rem;
    width: 1rem;
}

.addButton.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background: #F8FAFC;
    color: #94A3B8;
    pointer-events: none;
}

.addButton:hover:not(.disabled) {
    background: #3b4d9b;
    box-shadow: 0 4px 20px rgba(147, 152, 199, 0.2);
}

.addButton:active:not(.disabled) {
    transform: translateY(1px);
}

@media (max-width: 48rem) {
    .contentSection {
        padding: 0.75rem;
    }

    .addButton {
        width: 100%;
        margin: 1rem 0 0 0;
    }
}