/* NewFieldForm.module.css */
.form {
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin: 0 auto;
  color: black;
}

.form h3 {
  margin-bottom: 20px;
  font-size: 1.5em;
  color: #333;
}

.inputGroup {
  margin-bottom: 20px;
}

.inputGroup label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.inputGroup input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1em;
  transition: border-color 0.3s;
}

.inputGroup input:focus {
  border-color: #007bff;
  outline: none;
}

.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.buttonGroup button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.buttonGroup button:first-child {
  background-color: #28a745;
  color: white;
}

.buttonGroup button:first-child:hover {
  background-color: #218838;
}

.buttonGroup button:last-child {
  background-color: #dc3545;
  color: white;
}

.buttonGroup button:last-child:hover {
  background-color: #c82333;
}
